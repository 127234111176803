import '../scss/index.scss';
require('jquery.easing');
import 'cookieconsent';
import anime from 'animejs/lib/anime.es.js';
import AOS from 'aos';
import Gradient from './Gradient';
import parallax from 'jquery-parallax.js';
require('jquery-parallax.js');

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons/faGlobeAmericas';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';

library.add(faFacebookF);
library.add(faInstagram);
library.add(faLink);
library.add(faPlus);
library.add(faGlobeAmericas);
library.add(faCircle);

dom.watch();

let endpoint = 'https://agencijaepic.si/blog/wp-json/wp/v2/';
let cookiesMessage = "Spletna stran za svoje delovanje in spremljanje statistike uporablja spletne piškotke, ki ne hranijo osebnih podatkov.";
let cookiesDismiss = "ZAPRI";
let cookiesReadMore = "Preberite več";
let cookiesURL = "/pravna-obvestila.html";
let blogReadMore = "Preberite";

if($('html[lang="en"]').length > 0) {
    endpoint = 'https://agencijaepic.si/blog/en/wp-json/wp/v2/';
    cookiesMessage = "The website uses web cookies for its operation and monitoring of statistics, which do not store personal data.";
    cookiesDismiss = "CLOSE";
    cookiesReadMore = "Learn More";
    cookiesURL = "/en/legal-notice.html";
    blogReadMore = "Read more";
} else if($('html[lang="hr"]').length > 0) {
    endpoint = 'https://agencijaepic.si/blog/hr/wp-json/wp/v2/';
    cookiesMessage = "Web stranica koristi web kolačiće za svoj rad i praćenje statistika, koji ne pohranjuju osobne podatke.";
    cookiesDismiss = "ZATVORI";
    cookiesReadMore = "Pročitaj više";
    cookiesURL = "/hr/pravne-obavijesti.html";
    blogReadMore = "Pročitaj";
}

const ajaxEndpoint = 'https://agencijaepic.si/blog/wp-admin/admin-ajax.php';

jQuery(function () {
    'use strict';

    $('parallax-window').parallax({speed: 0});

    $(document).on('click', 'a.scroll-to', function (event) {
		event.preventDefault();

		$('html, body').animate({
			scrollTop: $($.attr(this, 'href')).offset().top - 50
		}, 500);
	});

    $("a[href='#top']").on('click', function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });

    // Domino animation with letters
    var textWrappers = document.querySelectorAll('.ml10 .letters');
    textWrappers.forEach((textWrapper) => {
        if(textWrapper) {
            textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        }
    });
    anime.timeline({loop: false})
    .add({
        targets: '.ml10 .letter',
        rotateY: [-90, 0],
        duration: 1300,
        delay: (el, i) => 45 * i
    });

    // Scroll down when clicked on arrow in header
    $("#scroll-down").on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: ($("header").outerHeight() - 65)
        }, 400, "easeInOutExpo");
    });

    // Scroll down when clicked on arrow in section
    $(".arrow-down").on('click', function(e) {
        e.preventDefault();
        let sec = $(this).parent();
        $('html, body').animate({
            scrollTop: (sec.position().top + sec.outerHeight(true))
        }, 400, "easeInOutExpo");
    });

    // Animate on scroll
    AOS.init({
		once: true,
		easing: 'ease-out-back',
		duration: 400,
        offset: 100,
        anchorPlacement: "top-center"
    });

    // Smooth scrolling using jQuery easing
    $('a.js-scroll-trigger[href*="#"]:not([href="#"]), .js-scroll-trigger a[href*="#"]:not([href="#"])').on('click', function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: (target.offset().top - 80)
                }, 400, "easeInOutExpo");
                return false;
            }
        }
    });

    if ($(".form-control").length) {
        $(".form-control").blur(function () {
            if ($(this).val()) {
                $(this).addClass("filled");
            }
        });

        $(".form-control").focus(function () {
            if ($(this).hasClass('filled')) {
                $(this).removeClass("filled");
            }
        });
    }

    function closeHamburgerMenu() {
        $(".hamburger").toggleClass("is-active");
        $(".nav-slide").toggleClass("open");
        $("body").toggleClass("navbar-is-active");
    }

    // Hamburger menu animation
    $(".hamburger").on('click', function () {
        closeHamburgerMenu();
    });

    // Close hamburger
    $(".slide-overlay").on('click', function () {
        closeHamburgerMenu();
    });

    // Close amburger on Escape button
    $(document).keyup(function(e) {
        if (e.key === "Escape") { // escape key maps to keycode `27`
            closeHamburgerMenu();
       }
   });

    // Collapse Navbar
    let navbarCollapse = function () {
        if ($('#mainNav').offset().top > 50) {
            $('#mainNav').addClass('scrolled');
        } else {
            $('#mainNav').removeClass('scrolled');
        }
    };
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).on('scroll', navbarCollapse);

    // Contact form - Kontakt
    jQuery("#submit-contact-form").on('click', function (e) {
        e.preventDefault();
        var errors = [];
        var contactName = jQuery("#contact-name");
        var contactCompany = jQuery("#contact-company");
        var contactPhone = jQuery("#contact-phone");
        var contactEmail = jQuery("#contact-email");
        var contactMessage = jQuery("#contact-message");
        var terms = jQuery("#terms");

        jQuery(".form-error").removeClass("form-error");
        jQuery(".form-errors-notice, .form-success-notice, .gdpr-error-group").hide();

        if(!checkField(contactName)) {
            contactName.parent().addClass("form-error");
            errors.push("contactName");
        }

        if(!checkField(contactEmail)) {
            contactEmail.parent().addClass("form-error");
            errors.push("contactEmail");
        }

        //Validate email
        if(contactEmail.val() != '') {
            if(!validateEmail(contactEmail.val())) {
                contactEmail.parent().addClass("form-error");
                errors.push("contactEmail-validation");
            }
        }

        if(!checkField(contactMessage)) {
            contactMessage.parent().addClass("form-error");
            errors.push("contactMessage");
        }

        //Check if Terms are checked
        if(!terms.is(':checked')) {
            terms.parent().addClass("form-error");
            errors.push("terms");
        }

        //CHECK IF ALL GOOD
        if(errors.length == 0) {
            const form = jQuery('#form-contact-form');
            let data = form.serialize();
            let action = 'sendContactForm';
            let formActionName = form.data('form-name');

            if(formActionName !== undefined && formActionName !== '') {
                action = formActionName;
            }

            data += "&action=" + action;

            jQuery.ajax({
                type: 'POST',
                dataType : "json",
                url: ajaxEndpoint,
                data: data,
                complete: function (json) {
                    var jsonData = jQuery.parseJSON(json.responseText);

                    if(jsonData.status) {
                        // ga('send', 'event', 'Kontakt poslan', 'kontakt poslan');
                        $('#form-contact-form').trigger("reset");

                        $(".form-success-notice").show();
                    } else {
                        console.log("FAILED MESSAGE!");
                    }
                }
            });

        } else {
            jQuery(".form-errors-notice").show();
            jQuery(".form-errors-notice").text("Preverite, ali ste izpolnili in označili vsa obvezna polja.");
        }
    });

    jQuery("#gdpr-1").on('change', function() {
        if(this.checked) {
            jQuery('input:checkbox').not(this).prop('checked', this.checked);
        } else {
            jQuery('input:checkbox').not(this).prop('checked', false);
        }
    });

    $(".btn-copy-link").on('click', function(e) {
        e.preventDefault();
        const url = $(this).data("url");
        const copied = $(this).data("copied");

        copyTextToClipboard(url);

        $(this).addClass("copied");
        $(this).find(".share-popup-label").text(copied);
    });

    $('#website-exists').on('change', function() {
        if($(this).is(':checked')) {
            console.log("checked");
            $('#url').prop('disabled', true);
        } else {
            $('#url').prop('disabled', false);
        }
    });

    $('[name="tip"]').on('change', function() {
        $("#brief-project-type").val($(this).val());
        $(".section-brief").show();

        setTimeout(function() {
            $('html, body').animate({
                scrollTop: ($("header").outerHeight() - 65)
            }, 400, "easeInOutExpo");
        }, 500);
    });

    $("#step-1").on('click', function() {
        const radioTip = $('[name="tip"]:checked').val();
        const radioTipIsChecked = $('[name="tip"]').is(':checked');

        var errors = [];
        var briefFullname = jQuery("#fullname");
        var briefEmail = jQuery("#email");
        var briefPhone = jQuery("#phone");

        if(!checkField(briefFullname)) {
            briefFullname.parent().addClass("form-error");
            errors.push("briefFullname");
        }

        if(!checkField(briefEmail)) {
            briefEmail.parent().addClass("form-error");
            errors.push("briefEmail");
        }

        //Validate email
        if(briefEmail.val() != '') {
            if(!validateEmail(briefEmail.val())) {
                briefEmail.parent().addClass("form-error");
                errors.push("briefEmail-validation");
            }
        }

        if(!checkField(briefPhone)) {
            briefPhone.parent().addClass("form-error");
            errors.push("briefPhone");
        }

        if(errors.length == 0) {
            if(radioTipIsChecked) {
                window.scrollTo(0, 0);
                $("body").addClass("step-1-completed");

                $('.step-2-main').addClass("show");

                console.log(radioTip);

                if(radioTip == 'Spletna stran') {
                    $('.step-2-main-website').addClass("show");
                } else if(radioTip == 'Spletna trgovina') {
                    $('.step-2-main-webshop').addClass("show");
                }
            } else {
                $('.step-1-errors').show();
                $('.step-1-errors').text("Izbrati morate tip projekta (spletna stran ali spletna trgovina).")
            }
        } else {
            $('.step-1-errors').show();
            $('.step-1-errors').text("Izpolniti morate vsa obvezna polja.")
        }
    });

    $("#step-2-website").on('click', function() {
        var errors = [];
        var briefWebsiteTextareaAboutProject = jQuery("#website-textarea-about-project");
        var briefWebsiteTextareaChangeProject = jQuery("#website-textarea-change-project");
        var briefWebsiteTextareaKeepProject = jQuery("#website-textarea-keep-project");
        var briefPWebsiteTextareaWhyProject = jQuery("#website-textarea-why-project");

        //CHECK IF ALL GOOD
        if(errors.length == 0) {
            var data = jQuery('#form-brief').serialize();
            var action = 'sendWebsiteForm';
            data += "&action=" + action;

            jQuery.ajax({
                type: 'POST',
                dataType : "json",
                url: ajaxEndpoint,
                data: data,
                complete: function (json) {
                    var jsonData = jQuery.parseJSON(json.responseText);

                    if(jsonData.status) {
                        console.log(jsonData.status);
                        $('#form-brief').trigger("reset");
                        window.location.href = "/brief/hvala.html";
                    } else {
                        console.log("FAILED MESSAGE!");
                    }
                }
            });

        } else {
            jQuery(".form-errors-notice").show();
            jQuery(".form-errors-notice").text("Preverite, ali ste izpolnili in označili vsa obvezna polja.");
        }
    });

    $("#step-2-webshop").on('click', function() {
        var errors = [];
        var briefWebshopTextareaAboutProject = jQuery("#webshop-textarea-about-project");
        var briefWebshopTextareaChangeProject = jQuery("#webshop-textarea-change-project");
        var briefWebshopTextareaKeepProject = jQuery("#webshop-textarea-keep-project");
        var briefPWebhopeTextareaWhyProject = jQuery("#webshop-textarea-why-project");

        //CHECK IF ALL GOOD
        if(errors.length == 0) {
            var data = jQuery('#form-brief').serialize();
            var action = 'sendWebshopForm';
            data += "&action=" + action;

            jQuery.ajax({
                type: 'POST',
                dataType : "json",
                url: ajaxEndpoint,
                data: data,
                complete: function (json) {
                    console.log(json);
                    var jsonData = JSON.parse(json.responseText);
                    console.log(jsonData);

                    if(jsonData.status) {
                        $('#form-brief').trigger("reset");
                        window.location.href = "/brief/hvala.html";
                    } else {
                        console.log("FAILED MESSAGE!");
                    }
                }
            });

        } else {
            jQuery(".form-errors-notice").show();
            jQuery(".form-errors-notice").text("Preverite, ali ste izpolnili in označili vsa obvezna polja.");
        }
    });

    //Check if Terms are checked
    if($(".form-checkbox-single .custom-checkbox label input").is(':checked')) {
        $(this).parent().parent().parent().find(".form-checkbox-single-wrapper").addClass("show");
    }

    $(".form-checkbox-single input").on('change', function() {
        const element = $(this).parent().parent().parent().next(".form-checkbox-single-content");
        if($(this).is(':checked')) {
            element.addClass("show");
        } else {
            element.removeClass("show");
        }
    });

    $("#add-url-website").on('click', function() {
        const htmlBlock =
            '<div class="form-col-half">' +
                '<input type="text" placeholder="Vnesite URL naslov želene strani." class="form-control form-control-shadow" name="website-text-url-name[]">' +
                ' <textarea class="form-control form-control-shadow form-control-textarea-2" placeholder="Kaj vam je všeč na zgoraj vnešeni strani?" spellcheck="false" name="website-text-url-desc[]"></textarea>' +
            '</div>';

        $(".append-url-website").append(htmlBlock);
    });

    $("#add-url-webshop").on('click', function() {
        const htmlBlock =
            '<div class="form-col-half">' +
                '<input type="text" placeholder="Vnesite URL naslov želene trgovine." class="form-control form-control-shadow" name="webshop-text-url-name[]">' +
                ' <textarea class="form-control form-control-shadow form-control-textarea-2" placeholder="Kaj vam je všeč na zgoraj vnešeni trgovini?" spellcheck="false" name="webshop-text-url-desc[]"></textarea>' +
            '</div>';

        $(".append-url-webshop").append(htmlBlock);
    });

    function loadVideoHeader() {
        if ($('#video-header').length) {
            document.getElementById('mp4_src_header').src =
                'assets/videos/gorenje/gorenje-header.mp4';
            document.getElementById('webm_src_header').src =
                'assets/videos/gorenje/gorenje-header.webm';
            document.getElementById('ogg_src_header').src =
                'assets/videos/gorenje/gorenje-header.ogg';
            document.getElementById('video-header').load();
        }
    }

    function loadVideoBody() {
        if ($('#video-body').length) {
            document.getElementById('mp4_src_body').src =
                'assets/videos/gorenje/gorenje-body.mp4';
            document.getElementById('webm_src_body').src =
                'assets/videos/gorenje/gorenje-body.webm';
            document.getElementById('ogg_src_body').src =
                'assets/videos/gorenje/gorenje-body.ogg';
            document.getElementById('video-body').load();
        }
    }

    function loadVideoFooter() {
        if ($('#video-footer').length && $(window).width() > 991) {
            document.getElementById('mp4_src_footer').src =
                'assets/videos/gorenje/gorenje-footer.mp4';
            document.getElementById('webm_src_footer').src =
                'assets/videos/gorenje/gorenje-footer.webm';
            document.getElementById('ogg_src_footer').src =
                'assets/videos/gorenje/gorenje-footer.ogg';
            document.getElementById('video-footer').load();
        }
    }

    $(window).on('load', function() {
        loadVideoHeader();
        loadVideoBody();
        loadVideoFooter();
    });
});


/**
 * Get 5 latest blog posts and display in blog section
 */
function getPostsSection() {
    let i = 0;
    let x = 0;
    let col = 0

	$.ajax({
		dataType: "json",
		url: endpoint + "posts/?per_page=5",
		success: function(result) {
			let items = [];

			$.each(result, function(key, val) {
				/*let media_url = val._links["wp:featuredmedia"][0].href;
				let media_image_url = null;
				$.ajax({
					dataType: "json",
					async: false,
					url: media_url,
					success: function(result_img) {
						if (typeof result_img.media_details.sizes["blog71x"] === 'undefined') {
							media_image_url = '';
						} else {
                            if(i % 2 == 0) {
                                media_image_url = result_img.media_details.sizes["blog71x"].source_url;
                            } else {
                                media_image_url = result_img.media_details.sizes["blog51x"].source_url;
                            }
						}
					}
				});*/

                const $blogPostsTemplate = $('.blog-posts-clone');
                let date = new Date(val.date)
                const month = ["januarja","februarja","marca","aprila", "maja", "junija","julija","avgusta","septembra","oktobra","novembra","decembra"];
                const readingTime = calculateReadingTime(val.content.rendered);
                let newDateFormat = date.getDay() + ". " + month[date.getMonth()] + " " + date.getFullYear()
                let $blogPostClone = $blogPostsTemplate.clone()
                let readingLabel = generateReadingLabel(readingTime)

                $('a', $blogPostClone).attr('href', val.link)
                $('.date', $blogPostClone).text(newDateFormat)
                $('.reading-time', $blogPostClone).text(readingTime + ' ' + readingLabel)
                $('.title', $blogPostClone).text(val.title.rendered)

                items.push($blogPostClone.html());

                //items.push( '<div class="col-lg" data-aos="fade-up"><a href="' + val.link + '" class="item-hover"><div class="item-wrapper"><div class="item-content"><div class="meta"><span class="date">' + newDateFormat + '</span><span class="reading-time">' + readingTime + ' minute read</span></div><h4>' + val.title.rendered + '</h4></div></div></a></div>' );
			});

			$( "<div/>", {
				"class": "stories-wrapper-inner",
				html: items.join( "" )
			}).appendTo( "body.home:not(.blog) .section-stories .stories-wrapper" );
		}
	});
}
window.addEventListener('load', getPostsSection);


/**
 * estiumate reading time
 */

function calculateReadingTime(text) {
    const wpm = 225;
    const words = text.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);
    return time;
}

function generateReadingLabel(time) {
    return time == 1
        ? 'minuta branja'
        : time == 2
            ? 'minuti branja'
            : time >= 3 && time <= 4
                ? 'minute branja'
                : 'minut branja'
}

/**
 * Checks element field is it's empty
 */
function checkField(field) {
    if (field.val() == '') {
        return false;
    }
    return true;
}

/**
 * Checks element value if it's valid email
 */
function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

function loadMatomo() {
    let matomo = document.createElement('script');
    matomo.innerHTML = "var _paq=window._paq=window._paq||[];_paq.push(['trackPageView']);_paq.push(['enableLinkTracking']);(function(){var u=\"//analytics.agencijaepic.si/\";_paq.push(['setTrackerUrl',u+'matomo.php']);_paq.push(['setSiteId','1']);var d=document,g=d.createElement('script'),s=d.getElementsByTagName('script')[0];g.async=true;g.src=u+'matomo.js';s.parentNode.insertBefore(g,s)})();";
    document.head.appendChild(matomo);
}
// window.addEventListener('load', loadMatomo);

/*
 * Load Google Tag Manager
 */
function loadAnalytics() {
    var gtm = document.createElement('script');
    gtm.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NNWT7PW');";
    document.head.appendChild(gtm);
}
// window.addEventListener('load', loadAnalytics);

/*
 * Load Facebook Pixel
 */
function loadFacebookPixel() {
    var fbp = document.createElement('script');
    fbp.innerHTML = "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '856079644929029');fbq('track', 'PageView');";
    document.head.appendChild(fbp);
}
window.addEventListener('load', loadFacebookPixel);

/*
 * Load Cookie Consent
*/
function loadCookieConsent() {
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#80bc41",
                "text": "#ffffff"
            },
            "button": {
                "background": "#ffffff"
            }
        },
        "theme": "classic",
        "content": {
            "message": cookiesMessage,
            "dismiss": cookiesDismiss,
            "link": cookiesReadMore,
            "href": cookiesURL
        }
    });
}
window.addEventListener('load', loadCookieConsent);

function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }

    navigator.clipboard.writeText(text).then(function() {
        console.log('Copying to clipboard was successful!');
    }, function(err) {
        console.error('Async: Could not copy text: ', err);
    });
}

window.onload = (event) => {
    new Gradient().initGradient('#gradient-canvas-main')
    //new Gradient().initGradient('#gradient-canvas-color')
};
